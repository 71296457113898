import React from "react";
import { connect, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import { TermsAndConditonsQuery } from "../../prismic/staticQueries";
import Layout from "../../layout";
import { Subscription, BreadCrumbBanner } from "../../components/common";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CountUp from "react-countup";
import banner_image from "../../images/siteImages/privacypolicy/terms2.jpg";
import PaymentSlider from "./PaymentSlider";

const TermsAndConditions = () => {
  const language = useSelector((state) => state.language);
  console.log("language", language);

  const TermsAndConditionsData = TermsAndConditonsQuery(language);
  console.log("test");
  // // const { seo_page_title, seo_page_description, seo_page_keywords } =
  // //   PrivacyPolicyData;
  const {
    termsandconditions_heading,
    termsandconditions_body,
  } = TermsAndConditionsData;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (

    <Layout >
      <BreadCrumbBanner banner_image={banner_image} />
      <section className="sectionbg p-top-10 p-bottom-80 faqs">
        <Container className="">
          <Row>
            <Col lg={12}>
              <h2 className="pt-5 mt-5 text-bold-md text-black">
                Terms and Conditions
              </h2>
              <h4 className="pt-5 my-3 text-bold-md text-black">Rox Capitals Ltd Website</h4>
              <p className="text-gray my-3">Rox Capitals LTD is pleased to provide you with information, content, tools, products and services on the Rox Capitals Sites. These terms and conditions govern important disclosures and information related to the Rox Capitals Sites and Rox Capitals products and services. Your use of the Rox Capitals Sites or services provided by Rox Capitals is subject to these terms and conditions. These terms and conditions are a binding agreement between you and Rox Capitals. Your use of the Rox Capitals Sites constitutes your acceptance of these terms and conditions and other legal statements contained on the Rox Capitals Sites. Your use of the Rox Capitals Sites is governed by the version of the terms and conditions in effect as on the date, you access the Rox Capitals Sites.</p>
              <p className="text-gray my-3">These terms and conditions are in addition to the Rox Capitals Privacy Policy (which is incorporated here by reference) and any other agreements executed between you and Rox Capitals, including any customer or account agreements, and any other agreements that govern your use of information, content, tools, products and services available on and through Rox Capitals Sites.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">Use of The Rox Capitals Sites</h4>
              <p className="text-gray my-3">The information provided on the Rox Capitals Sites or provided by Rox Capitals is intended only for your personal, non-commercial use, unless Rox Capitals has agreed in writing, and only for legitimate purposes, to do otherwise. Rox Capitals reserves the right to take appropriate legal action against any defaulting visitor or customer for any illegal or unauthorized use of the information. You may use any information, tools, products and services provided on Rox Capitals Sites only if you are of legal age as per the applicable law of your country and are competent to form a binding contract with Rox Capitals. Rox Capitals in its sole discretion, reserves the right to reject, refuse to post or remove any posting by you, or restrict, suspend or terminate your access to the information on Rox Capitals Sites. You further acknowledge and agree that if Rox Capitals disables access to your account, you may be prevented from accessing the services provided by Rox Capitals, account details or any files or other content which is contained in the account. Moreover, if you become aware of any objectionable material displayed on the Rox Capitals Sites, you should immediately notify Rox Capitals.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">Distribution and Responsibility of Visitors</h4>
              <p className="text-gray my-3">The information on the Rox Capitals Sites is not intended for distribution to, or use by, any person in any country or jurisdiction where such distribution or use would be contrary to applicable laws or regulations of that country. None of the services or investments referred to on the Rox Capitals Sites is available to persons residing in any country where the provision of such services or investments would be contrary to the applicable local laws or regulation. It is the responsibility of visitors or customers (as the case may be) to ascertain the terms of and comply with any applicable laws or regulations to which they are subject. Nothing on the Rox Capitals Sites shall be considered as a solicitation to buy or an offer to sell any product or service to any person in any jurisdiction where such offer, solicitation, purchase or sale would be unlawful under the applicable laws of such jurisdiction.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">No Professional Advice</h4>
              <p className="text-gray my-3">Rox Capitals does not, and does not intend to, provide legal, tax, investment or financial advice. Nothing in the Rox Capitals Sites shall be construed as creating a fiduciary, financial or other advisory relationship between you and Rox Capitals. You are solely responsible for determining whether any investment, investment strategy or related transaction is appropriate for you based on your personal investment objectives, financial circumstances and risk tolerance. You must make your own investigations regarding the accuracy, reliability and completion of information provided and consult your legal, tax or finance professional advisor regarding your specific situation, including your financial situation, objectives and goals.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">Intellectual Property</h4>
              <p className="text-gray my-3">The information on Rox Capitals Sites is protected by applicable intellectual property laws. Except as expressly stated herein, you will not without Rox Capitals’ prior written permission alter, modify, reproduce, distribute or commercially exploit any materials, including text, graphics, video, audio, software code, user interface design or logos, from the Rox Capitals Sites. The right to use the names, logos, trademarks, copyright and all other intellectual property rights (whether registered or not) in all of the material and software on Rox Capitals Sites remains exclusively with Rox Capitals or its licensors. Unless you have been expressly authorized to do so in writing by Rox Capitals, you will not use any trade mark, service mark, trade name, logo of Rox Capitals in a way that is likely or intended to cause confusion about the owner or authorized user of such marks, names or logos. If you infringe or threaten to infringe copyright or any other intellectual property right of Rox Capitals, or Rox Capitals has a reasonable suspicion of such infringement or threatened infringement, your relationship and arrangements with Rox Capitals will be terminated by Rox Capitals and Rox Capitals will have the right to constitute legal proceedings against you.</p>
              <p className="text-gray my-3">If you click on link of another website given on the Rox Capitals Sites, it does not suggest that Rox Capitals endorses, sponsors or is affiliated with the website, entity, service or product and that website may be subject to separate terms and conditions of use and policies.</p>
              <p className="text-gray my-3">The Rox Capitals Sites, excluding third party content, are original works of authorship published by Rox Capitals. Rox Capitals has the exclusive rights to reproduce, display, prepare derivative works or distribute. The names, logos, trademarks, copyright and all other intellectual property rights (whether registered or not) in all of the material and software on the Rox Capitals Sites are owned by Rox Capitals or its licensors. All third-party owned materials contained on Rox Capitals Sites are reproduced with the permission of the respective owners. Provisions related to non-disclosure, restriction on use of intellectual property owned by Rox Capitals and the use of Rox Capitals’ intellectual property will continue to apply, even after termination of the relationship between you and Rox Capitals.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">Void Where Prohibited</h4>
              <p className="text-gray my-3">Although the information on the Rox Capitals Sites including the products and services are accessible worldwide, not all features, products or services discussed, referenced, provided or offered through or on the Rox Capitals Sites are available to all persons or in all jurisdictions, or appropriate or available for use in certain jurisdictions. Rox Capitals reserves the right to limit or refuse, at its sole discretion, the provision and the quantity of the products and services.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">Third Party Content And Research</h4>
              <p className="text-gray my-3">Rox Capitals does not explicitly or implicitly endorse or approve such third party content. The third party content should not be construed as legal, tax, financial or investment advice. Your attention is drawn to the ‘no professional advice’ section above. While Rox Capitals makes every attempt to provide accurate and timely information to serve the needs of users or customers, neither Rox Capitals  nor third party content providers guarantee its accuracy, timeliness, completeness or usefulness, and are not responsible or liable for any such content, including any advertising, products, or other materials on or available from third party websites. Third party content is provided for informational purposes only and Rox Capitals specifically disclaims any liability for third party content available on Rox Capitals Sites. You will use third party content only at your own risk. The third party content providers expressly disclaim all warranties of any kind, express or implied, including without limitation any warranty of merchantability, fitness for a particular purpose or non-infringement.</p>
              <p className="text-gray my-3">Third party content providers and their parents, subsidiaries, affiliates, service providers, licensors, officers, directors or employees shall not be liable for any direct, indirect, incidental, sub_h or consequential damages arising out of or relating to the use or the inability to use the third party content, including but not limited to damages for loss of profits, use, data or other intangible damages, even if such party has been advised of the possibility of such damage.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">Accuracy of Information</h4>
              <p className="text-gray my-3">While Rox Capitals has made every effort to ensure the accuracy of the information on Rox Capitals Sites, the information and content on the Rox Capitals Sites is subject to change without notice and is provided for the sole purpose of assisting you to make independent investment decisions. Rox Capitals has taken reasonable measures to ensure the accuracy of the information on Rox Capitals Sites. However, Rox Capitals does not guarantee its accuracy and will not accept liability for any loss or damage that may arise directly or indirectly from the content or your inability to access Rox Capitals Sites, for any delay in or failure of the transmission or the receipt of any instruction or notifications sent through the Rox Capitals Sites. All content on Rox Capitals Sites is presented only as of the date published or indicated, and may be superseded by subsequent market events or for other reasons. In addition, you are responsible for setting the cache settings on your browser to ensure you are received.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">Prohibited Uses</h4>
              <p className="text-gray my-3">Since all servers have limited capacity and are used by many people, you should not use Rox Capitals Sites in any manner that could damage or overburden any Rox Capitals server, or any network connected to any Rox Capitals server. You should not use Rox Capitals Sites in any manner that would interfere with any other party’s use of Rox Capitals Sites.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">Exclusion of Warranties</h4>
              <p className="text-gray my-3">Rox Capitals does not make any express or implied warranties about the information on Rox Capitals sites or the information given while providing the services, including but not limited to implied warranties of merchantability, fitness for a particular purpose, or non-infringement. The Rox Capitals sites are made available to you on a “as is” and “as available” basis and Rox Capitals does not warrant the accuracy or completeness of information on the Rox Capitals sites or that any defects or inaccuracies or errors in the software will be corrected.</p>
              <p className="text-gray my-3">Rox Capitals does not warrant that the Rox Capitals sites will meet your needs, or that they will be uninterrupted, timely, secure or error-free. Rox Capitals also makes no warranty that the results obtained from the use of Rox Capitals sites will be accurate or reliable, or that the quality of any products, services, information, or other material purchased or obtained by you through the Rox Capitals sites will meet your expectations. Rox Capitals will not be liable (whether under contract, tort (including negligence) or otherwise) for any loss or damage which may be incurred by you, as a result of your failure to keep personal and sensitive personal information secure and confidential.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">Disclaimer of Warranties and Liability</h4>
              <p className="text-gray my-3">To the maximum extent permitted by applicable laws, Rox Capitals will not be liable for any consequential, incidental, direct or indirect damages (including but not limited to lost profits, trading losses or damages that result from use or loss of use of the Rox Capitals sites, products or services and third party content, inconvenience or delay). This is true even if Rox Capitals has been advised of the possibility of such damages or losses, except as otherwise required by applicable laws, Rox Capitals` will not be liable to you or anyone else (whether under contract, or not (including negligence) or otherwise) for any loss resulting from a cause over which Rox Capitals does not have direct control, this includes failure of electronic or mechanical equipment or communications lines (including telephone, cable and internet), unauthorized access, viruses, theft, operator errors, severe or extraordinary weather (including flood, earthquake, or other act of god), fire, war, insurrection, terrorist act, riot, labour dispute and other labour problems, accident, emergency or action of government.</p>
              <p className="text-gray my-3">If you live in a state, country or jurisdiction that does not allow the limitation or exclusion of liability or incidental or consequential damages, some or all of these limitations and exclusions may not apply to you.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">Indemnification</h4>
              <p className="text-gray my-3">You agree to defend, indemnify and hold harmless Rox Capitals, its parents, affiliates, licensees and their respective directors, officers, employees and agents for, from and against all liabilities, claims damages and expenses, including attorneys’ fees, arising out of or in connection with your use of the Rox Capitals Sites or services provided by Rox Capitals, or your violation of the Privacy Policy or terms and conditions. Any indemnified party may participate, at its own expense, in the defence of any matter subject to indemnification by you and you agree to cooperate with such party in the defence of such claim.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">Confidentiality</h4>
              <p className="text-gray my-3">You acknowledge and agree that any instruction or communication transmitted to you or on your behalf via the Rox Capitals Sites is made at your own risk. You consent to and authorize Rox Capitals to rely and act on, and treat as fully authorized and binding upon you, any instruction given to Rox Capitals that Rox Capitals believes to have been given by you or on your behalf by any agent or intermediary whom Rox Capitals believe in good faith to have been duly authorized by you. The Privacy Policy available on the Rox Capitals Sites is applicable to all users and customers and are incorporated here as terms and conditions of use of Rox Capitals Sites by reference. You acknowledge and agree that Rox Capitals shall be entitled to rely upon your account number and/or password to identify you and agree you will not disclose this information to anyone not duly authorized by you.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">Termination</h4>
              <p className="text-gray my-3">Rox Capitals reserves the right, in its sole discretion, to restrict, suspend or terminate your access to all or any part of the Rox Capitals Sites at any time for any reason without prior notice. Rox Capitals may change, suspend or discontinue all or any aspect of the Rox Capitals Sites at any time, including the availability of any feature, database, information or content without prior notice. In the event of termination, you are no longer authorized to access the Rox Capitals Sites and the restrictions imposed on you with respect to material downloaded from the Rox Capitals Sites, the disclaimers and limitations of liability set forth in these terms and conditions shall survive. You may terminate the relationship with Rox Capitals at any time by closing your account with Rox Capitals.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">Governing Law and Jurisdiction</h4>
              <p className="text-gray my-3">Unless otherwise agreed, these terms and conditions and their enforcement are governed by the laws of Mauritius, without regard to principles of conflicts of law, and shall inure to the benefit of Rox Capitals’s successors and assigns, whether by merger, consolidation, or otherwise. This is the case regardless of whether you reside or transact business with Rox Capitals in of United Kingdom or elsewhere. Unless a dispute would be governed by an applicable arbitration clause, you irrevocably agree to submit to the jurisdiction of courts located in of United Kingdom, and hereby waive any objection to the convenience or propriety of venue therein or any similar grounds.</p>
              <p className="text-gray my-3">If you are dissatisfied with these terms and conditions, your sole and exclusive remedy is to discontinue using the Rox Capitals Sites.</p>
            </Col>
          </Row>
        </Container>
      </section>

      
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(TermsAndConditions);
